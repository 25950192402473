import { Card } from "@mui/material";

import PensionCalculator from "../../components/PensionCalculator";
import { SEO } from "../../components/SEO";

function PensionCalculatorPage(): JSX.Element {
	return (
		<Card sx={{ m: 3, pt: 6 }}>
			<PensionCalculator />
		</Card>
	);
}

export default PensionCalculatorPage;

export const Head = (): JSX.Element => (
	<>
		<SEO title="Penzijní kalkulačka" />
		<script src="/iframeResizer.contentWindow.min.js"></script>
		<base target="_blank"></base>
	</>
);
